import noproduct from '../../assets/placeholders/noproduct.png'
import nobrand from '../../assets/placeholders/nobrand.png'
const helper_text = 'Este campo debe contener min un caracter'


export const translations = {
    title:'Detalle de producto',
    nopicture:noproduct,
    menu:[{id:1, label:'Información'}, {id:2, label:'Estadísticas'}],
    product_info:{
        general_info:{
            button:'Editar',
            edit_modal:{
                title:'Editar',
                button:'Guardar'
            },
            price_title:'Precio',
            quantity_title:'Cantidad',
            sat_title:'Información fiscal',
            nobrand:nobrand,
            form:{
                name:{tag:'Producto', helperText:helper_text},
                code:{tag:'Código', helperText:helper_text},
                location:{tag:'Ubicación', helperText:helper_text},
                description:{tag:'Descripción', helperText:helper_text},
                brand_id:{tag:'Marca', helperText:helper_text},
                purchase_group_id:{tag:'Grupo de compra', helperText:helper_text},
                quantity:{tag:'Actual', helperText:'Debe ser mayor a 0'},
                purchase:{tag:'Comprados', helperText:'Debe ser mayor a 0'},
                min_quantity:{tag:'Min', helperText:'Debe ser mayor a 0'},
                max_quantity:{tag:'Max', helperText:'Debe ser mayor a 0'},
                retail_price:{tag:'Max', helperText:helper_text},
                wholesale_price:{tag:'Min', helperText:helper_text},
                cost:{tag:'Costo', helperText:helper_text},
                sat_unit_code:{tag:'Unidad', helperText:'Este campo es requerido'},
                sat_product_code:{tag:'Código', helperText:'Este campo es requerido'},
            }
            
        },
        equivalences:{
            title:'Equivalencias',
            code:'Código',
            description:'Producto',
            id:'ID',
            brand:'Marca',
            button:'Agregar'
        },
        complementary:{
            title:'Complementarios',
            code:'Código',
            description:'Producto',
            id:'ID',
            brand:'Marca',
            button:'Agregar'
        },
        
    }
    
}

export default translations