import { useEffect, useState } from "react"
import { private_server } from "../../../../config"
import { onGetCatalogs, onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import moment from "moment"
import useProduct from "../../../../hooks/api/useProduct"


const useProducts = ({language, content}) => {

    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const productManager = useProduct()


    const [top, setTop] = useState([]);
    const [product_data, setProductData] = useState([]);

    const [products, setProducts] = useState([]);
    const [product_selected, setProductSelected] = useState(1);
    const [year2, setYear2] = useState(moment().format("DD MM YYYY").substring(6));
    
    const [month, setMonth] = useState(moment().format("M"))
    const [year, setYear] = useState(moment().format("DD MM YYYY").substring(6));

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_product'})
    const [search, setSearch] = useState('')


    //console.log(moment().format("M"))

    useEffect(() => {
        actions.onInitModule()
    }, [search, year, month, tableFilter])

    useEffect(() => {
        actions.onInitModule2()
    }, [product_selected, year2])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _year = moment().format("DD MM YYYY")
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order, month:month, year:year}
                let filter = {filter:
                    {"OR":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"code","operator":"LIKE","value":`%${search}%`}
                    ]}           
                }
                const req = await private_server.get(`analytic/top`, {params:{...params, ...filter}})
                //console.log(req.data.data)
                setTop(req.data.data.top_products)
                setTotal(req.data.data.count)              
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onInitModule2: async() => {
            try {
                setLoading2(true)
                updateAuthorizationHeader(private_server)
                //console.log('holaaaa')
                const req = await private_server.get(`analytic/product/${product_selected}?year=${year2}`)
                console.log(req.data.data)
                setProductData(req.data.data.product_data)       
                const req_prod = await private_server.get(`/product/${product_selected}`)  
                const _product = req_prod.data.data.product
                setProducts([{value:product_selected, code:_product.code, brand:_product.brand, name:_product.name, label:_product.code + ' - ' + _product.brand + ' - ' + _product.name}]) 
                //setProducts([`${_product.code} - ${_product.brand} - ${_product.name}`])
                setLoading2(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading2(false)
            }
        },
        onChangeTableFilter: (newTableFilter) => { 
            setTableFilter(newTableFilter)
        },
        onChangeSearch: (value)=>{
            setSearch(value)
        },
        onChangeYearFilter: async(value) => {
            //console.log(value)
            setYear(value)
        },
        onChangeMonthFilter: (value) =>{
            //console.log(value)
            setMonth(value)
        },
        onSearchProduct: async(e) => {
            //console.log(e.target.value)
            if(!e || e.target.value === "" || e.target.value === 0) return
            try {

                const params = {limit:150, search:search}
                const _productdata = await productManager.onSearch(params)
                const cat = _productdata.data.map(item => {
                //return {value:item.id_product, label:item.name}})
                return {value: item.id_product, label:item.code + ' - ' + item.brand + ' - ' + item.name, code: item.code, brand:item.brand, name:item.name}
                })
                setProducts(cat)

          } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
          }
        },
        onChangeProductFilter: (e, item) =>{
            //console.log(e.target.value)
            if(item){
                console.log(item.value)
                setProductSelected(item.value)
            }
        },
        onChangeYearFilter2: async(value) => {
            setYear2(value)
        },
      
    }

    const system = {loading, sending, error, loading2}
    const view_data = {total, search, top, tableFilter, product_data, products, month, year, year2}

    return {system, actions, view_data} 
}

export default useProducts