import moment from "moment"
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'
import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import useProduct from "../../../hooks/api/useProduct"

const useProductsView = ({ history, language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const productManager = useProduct()

    const [products, setProducts] = useState([])

    const [modals, setModals] = useState({
        add_product:false
    })

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0})
    const [search, setSearch] = useState('')

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order, search: search}
                /*const filter = {filter:
                    {"OR":[
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"code","operator":"LIKE","value":`%${search}%`},
                        {"field":"brand","operator":"LIKE","value":`%${search}%`},
                        {"field":"description","operator":"LIKE","value":`%${search}%`},
                    ]}           
                }*/

                
                if(!search){
                    const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                    const filter = {filter:
                        {"OR":[
                            {"field":"name","operator":"LIKE","value":`%${search}%`},
                            {"field":"code","operator":"LIKE","value":`%${search}%`},
                            {"field":"brand","operator":"LIKE","value":`%${search}%`},
                            {"field":"description","operator":"LIKE","value":`%${search}%`},
                        ]} 
                    } 
                    const req = await private_server.get(`product/all`, {params:{...params, ...filter}})
                    const _products = req.data.data
                    setProducts(_products.products)
                    setTotal(_products.count)
                    setLoading(false)
                }else{
                    const _productdata = await productManager.onSearch(params)
                    setProducts(_productdata.data)
                    setTotal(_productdata.count)
                    setLoading(false)
                }
                
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }   
        },
        onSelectItem: (id_product) => {
            history.push(`products/${id_product}`)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:1, order_by:'id_product'}),
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onCompleted:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`product/all`, {params:{...tableFilter}})
                console.log(req)
                const _products = req.data.data
                setProducts(_products.products)
                setTotal(_products.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onClearError:()=>{
            setError(null)
        },
        onDownloadExcel: async()=> {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`product/all`)
                //console.log(req)
                const _products = req.data.data.products
              
                
                //
                const workbook = new ExcelJS.Workbook();
              
                const _rows = _products && _products.length !== 0 ? _products.map((el) => {
                    return [el.id_product, el.code, el.name, el.description, el.brand_id, el.brand, el.purchase_group_id, el.purchase_group,
                        el.location, el.min_quantity, el.max_quantity, el.quantity, el.cost, el.retail_percentage, el.wholesale_percentage,
                        el.retail_price, el.wholesale_price, el.sat_unit_code, el.sat_product_code]
                }) : []

              
               
                const worksheet = workbook.addWorksheet('Inventario');
                worksheet.addTable({
                    name: 'Inventario',
                    ref: 'A1',
                    headerRow: true,
                    //totalsRow: true,
                    /* style: {
                      theme: 'TableStyleDark3',
                      showRowStripes: true,
                    }, */
                    columns: [
                        {name:'ID'},
                        {name:'Código'},
                        {name:'Producto'},
                        {name:'Descripción'},
                        {name:'ID marca'},
                        {name:'Marca'},
                        {name:'ID grupo de compra'},
                        {name:'Grupo de compra'},
                        {name:'Ubicación'},
                        {name:'Cantidad mínima'},
                        {name:'Cantidad máxima'},
                        {name:'Cantidad'},
                        {name:'Costo'},
                        {name:'Porcentaje de menudeo'},
                        {name:'Porcentaje de mayoreo'},
                        {name:'Precio de menudeo'},
                        {name:'Precio de mayoreo'},
                        {name:'Unidad fiscal'},
                        {name:'Código fiscal'},
                    ],
                    rows: _rows
                  })

                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

                workbook.xlsx.writeBuffer()
                .then((file) => {
                    const blob = new Blob([file], { type: EXCEL_TYPE });
                    FileSaver.saveAs(blob, `Inventario.xlsx`);
                })
                
                /* const blob = new Blob([_missing], {
                    //type: "application/vnd.ms-excel;charset=utf-8"
                    type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                  });
                FileSaver.saveAs(blob, "Report.xlsx"); */
                
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {products, total, tableFilter, search}

    return {system, modals, view_data, actions}
}

export default useProductsView