import moment from "moment"
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'
import { useEffect, useState } from "react"
import { private_server } from "../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../shared/utility"

const useMissingView = ({ language }) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    
    const [missing, setMissing] = useState([])

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:0, order_by:'brand'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        edit_client:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                let filter = {filter:
                    {"OR":[
                        {"field":"code","operator":"LIKE","value":`%${search}%`},
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                        {"field":"purchase_group","operator":"LIKE","value":`%${search}%`},
                        {"field":"brand","operator":"LIKE","value":`%${search}%`},
                    ]}           
                }
                const req = await private_server.get(`product/pendingpurchase`, {params:{...params, ...filter}})
                //console.log(req)
                const _missing = req.data.data
                setMissing(_missing.pending_purchase_products)
                setTotal(_missing.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
            setLoading(false)
        },
        onSelectItem: (id) => {
            //history.push(`products/${id}`)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onResetTableFiler: () => setTableFilter({limit:10, offset:0, order:0, order_by:'brand'}),
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => { 
            //let newTableFilter = {...tableFilter}
            //newTableFilter.offset = 0
            setTableFilter(newTableFilter)
        },
        onDownloadExcel: async()=> {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`product/pendingpurchase`)
                //console.log(req)
                const _missing = req.data.data.pending_purchase_products
                //const json2export = JSON.stringify(_missing)
                //console.log(json2export)
                
                //
                const workbook = new ExcelJS.Workbook();
                //workbook.creator = '';
                //workbook.created = new Date();
                const _rows = _missing && _missing.length !== 0 ? _missing.map((el) => {
                    return [el.id_product, el.code, el.brand, el.name, el.description, el.quantity, el.purchase_quantity, el.pending_products, el.purchase_group]
                }) : []

                //console.log(_rows)
               
                const worksheet = workbook.addWorksheet('Faltantes');
                worksheet.addTable({
                    name: 'Faltantes',
                    ref: 'A1',
                    headerRow: true,
                    //totalsRow: true,
                    /* style: {
                      theme: 'TableStyleDark3',
                      showRowStripes: true,
                    }, */
                    columns: [
                        {name:'ID'},
                        {name:'Código'},
                        {name:'Marca'},
                        {name:'Producto'},
                        {name:'Descripción'},
                        {name:'Actuales'},
                        {name:'Comprados'},
                        {name:'A comprar'},
                        {name:'Grupo'},
                    ],
                    rows: _rows
                  })

                const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

                workbook.xlsx.writeBuffer()
                .then((file) => {
                    const blob = new Blob([file], { type: EXCEL_TYPE });
                    FileSaver.saveAs(blob, `Faltantes.xlsx`);
                })
                
                /* const blob = new Blob([_missing], {
                    //type: "application/vnd.ms-excel;charset=utf-8"
                    type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
                  });
                FileSaver.saveAs(blob, "Report.xlsx"); */
                
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {missing, tableFilter, total}

    return {system, modals, view_data, actions}
}

export default useMissingView