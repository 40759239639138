import { connect } from 'react-redux';
import Page from '../../../components/Structure/Layouts/PageAdmin'
import { USER_TYPES } from '../../../config';
import system_translations from '../../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddProductModal from './modals/AddProductModal/AddProductModal';
import useProductsView from './useProductsView';

const ProductsView = ({language, history, user}) => {

    const content = system_translations[language].views.products
    const {actions, view_data, system, modals} = useProductsView({history, language})

    let can_create = true
    if(user && user.user_type_id === USER_TYPES.VISOR ) can_create = false
    
    return(
        <Page title={content.title} error={system.error} onCloseErrorModal={actions.onClearError} language={language}>
            <AddProductModal open={modals.add_product} onClose={()=>actions.onChangeModalStatus('add_product', false)}
                language={language} onCompleted={actions.onCompleted} history={history}/>
            <SimpleTable 
                loading={system.loading}
                content={content.table} 
                data={view_data.products}
                onSelectedItem={actions.onSelectItem}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={can_create ? ()=>actions.onChangeModalStatus('add_product', true) : null}
                onUpdateSearch={actions.onResetTableFiler}
                onDownloadExcel={actions.onDownloadExcel}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
        user:state.user
    }
}

export default connect(mapStateToProps)(ProductsView) 