import { useEffect, useState } from "react"
import { private_server } from "../../../config"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"


const useProductView = ({match, language}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

    const [view, setView] = useState(1)

    const [product, setProduct] = useState(null)
    const [complements, setComplements] = useState([])
    const [equivalences, setEquivalences] = useState([])

    const [equ_id, setEquId] = useState(null)
    const [comp_id, setCompId] = useState(null)

    const [modals, setModals] = useState({
        edit_product:false,
        delete_product:false,
        add_equivalence:false,
        delete_equivalence:false,
        add_complement:false,
        delete_complement:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`product/${id}`)
                //console.log(req)
                const _product = req.data.data.product
                setProduct(_product)
                const req_comp = await private_server.get(`complement/all?limit=20&offset=0&order=0&order_by=id_complement&filter={"AND":[{"field":"main_product_id","operator":"=","value":"${id}"}]}`)
                //console.log(req_comp)
                const _complements = req_comp.data.data
                setComplements(_complements.complements)
                const req_eq = await private_server.get(`equivalence/all/product/${id}`)
                const _equivalences = req_eq.data.data.equivalences
                setEquivalences(_equivalences)
                setLoading(false)
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeView: (new_view) => {
            setView(new_view)
        },
        onProductCompleted: async () => {
            try{
                const req = await private_server.get(`product/${id}`)
                console.log(req)
                const _product = req.data.data.product
                setProduct(_product)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onDeleteEquivalence: (value) =>{
            setEquId(value)
            actions.onChangeModalStatus('delete_equivalence', true)
        },
        onEqCompleted: async () =>{
            try{
                const req_eq = await private_server.get(`equivalence/all/product/${id}`)
                const _equivalences = req_eq.data.data.equivalences
                setEquivalences(_equivalences)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onDeleteComplement: (value) =>{
            setCompId(value)
            actions.onChangeModalStatus('delete_complement', true)
        },
        onCompCompleted: async () =>{
            try{
                const req_comp = await private_server.get(`complement/all?limit=20&offset=0&order=0&order_by=id_complement&filter={"AND":[{"field":"main_product_id","operator":"=","value":"${id}"}]}`)
                const _complements = req_comp.data.data
                setComplements(_complements.complements)
            }catch(error){
                const _error = onGetErrorMessage(error, language)
                setError(_error ? _error.message : null)
            }
        },
        onClearError:()=>{
            setError(null)
        }
        
    }

    const system = {loading, sending, error}
    const view_data = {product, complements, view, equivalences, equ_id, comp_id}

    return {system, modals, actions, view_data} 
}

export default useProductView