import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import styles from '../../../../../styles/analyticsTableStyles'
import { nodata } from '../../../../../config'
import { onGetErrorMessage, onGetMonthName } from '../../../../../shared/utility'
import InputSelectFormAvatar from '../../../../../components/Forms/InputSelectFormAvatar'
import UserFilter from './UserFilter'


const UserTable = props => {

    const classes = styles()
    const { content, data, users, onChangeFilter, loading} = props

    const header = content.header

    return(
        <div> 
            <div className={classes.toolbar}>
                <Typography variant='subtitle1' className={classes.title}>{content.title}</Typography>
                <UserFilter options={users} onChange={onChangeFilter} />
            </div> 
            {loading ? <div style={{paddingBottom:12}}><LinearProgress /></div> : null}        
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='caption' className={classes.header}>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {month, upt, tpv, sales_total_units, return_total_units, sales_total, return_total, balance} = item

                                const month_name = onGetMonthName(month)

                                return(
                                    <TableRow key={key.toString()} className={classes.row} >
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{month_name}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{upt}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{tpv}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{sales_total_units}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{return_total_units}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{sales_total}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{return_total}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{balance}</Typography></TableCell>
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}     
        </div>
    )
}

export default UserTable

const users= {
    value:null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config: {
        id: 'name',
        type: 'text',
        fullWidth: true,
    },
    rules: {type: 'distance', min:1, max:255}
}