import { createContext, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useInterval } from '../../shared/utility';

// ----------------------------------------------------------------------

export const PrinterContext = createContext(null);

// ----------------------------------------------------------------------

PrinterProvider.propTypes = {
  children: PropTypes.node,
};

export function PrinterProvider({ children }) {

  
    const [ipaddress, setIPAddress] = useState("192.168.100.34");
    const [port, setPort] = useState("8008");
    const [status, setStatus] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [error, setError] = useState()

    const ePosDevice = useRef()
    const printer = useRef();

    useInterval(async() => {
        if(status && ePosDevice.current){
            console.log('Revisar conexion')
            const _status =  await ePosDevice.current.isConnected()
            console.log('EPOS status : ', _status)
            if(_status !== 1){
                console.log('Reconectar')
                setStatus(false)
                initialize()
            }
        }
    }, 10000)

    const onReconnect = useCallback(() => {
        console.log('Reconectar')
        setStatus(false)
        initialize()
    }, [])

  
    const initialize = useCallback(async () => {
        console.log('Initialize printer provider')         
        let ePosDev = new window.epson.ePOSDevice(); 
        ePosDevice.current = ePosDev
        setConnecting(true)
        const _status =  await ePosDevice.current.isConnected()
        if(_status === 0){
            console.log('Need to set a connection with printer')
            console.log(ipaddress, port)
            
            ePosDev.connect(ipaddress, port, (data) => {
                console.log('ePOSData', data)
                if (data === "OK") {
                    console.log('Conexion terminada con exito')
                    ePosDev.createDevice(
                        "local_printer",
                        ePosDev.DEVICE_TYPE_PRINTER,
                        { crypto: true, buffer: false },
                        (devobj, retcode) => {
                        if (retcode === "OK") {
                            console.log('Dispositivo creado')
                            printer.current = devobj;
                            //setLoading(false)
                            setError(null)
                            setConnecting(false)
                            setStatus(true);
                            //onPrintData()
                        } else {
                            //setLoading(false)
                            setError(retcode)
                            setConnecting(false)
                            throw retcode;
                        }
                        }
                    );
                } else {
                    //setLoading(false)
                    console.log('Hubo un error', data)
                    setError(data)
                    setConnecting(false)
                    throw data;
                }
            })

        }
    }, [status]);




  useEffect(() => {
    initialize();
  }, []);






  return (
    <PrinterContext.Provider
      value={{
        ipaddress,
        port,
        ePosDevice,
        printer,
        status,
        error,
        connecting,
        printer,
        onReconnect
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
}
