import React, { useState } from 'react'
import { CircularProgress, Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import styles from '../../../../../styles/analyticsTableStyles'
import { nodata } from '../../../../../config'
import { onGetCurrencyValue, onGetErrorMessage, onGetMonthName } from '../../../../../shared/utility'
import TotalFilter from './TotalFilter'
import cx from 'classnames'


const TotalTable = props => {

    const classes = styles()
    const { content, data, onChangeFilter, loading, onSelectItem, month_selected, year_selected} = props

    const header = content.header

    const [selected, setSelected] = useState(month_selected);

    const onInnerSelecteItem = (month, key) =>{
        setSelected(key)
        onSelectItem(month)
    }

    return(
        <div> 
            <div className={classes.toolbar}>
                <Typography variant='subtitle1' className={classes.title}>{content.title}</Typography>
                <TotalFilter options={content.filter_options} onChange={onChangeFilter} value={year_selected} />
            </div> 
            {loading ? <div style={{paddingBottom:12}}><LinearProgress /></div> : null}        
            {data && data.length ? (
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        {header ? (
                            <TableHead>
                                <TableRow>
                                    {header.map(item=>{
                                        return(
                                            <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                <Typography variant='caption' className={classes.header}>{item.label}</Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        ):null}
                        <TableBody>
                            {data.map((item,key) => {
                                const {month, upt, tpv, sales_total_units, return_total_units, sales_total,purchase_total_units, return_total, purchase_total, balance,
                                    sales_receipt, sales_nf_receipt, sales_invoice, total_cost } = item

                                const month_name = onGetMonthName(month)

                                return(
                                    <TableRow key={key.toString()} className={cx({[classes.clicleable_row]:true, [classes.selected_row]: key === selected})} 
                                    onClick={()=>onInnerSelecteItem(month, key)} >
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{month_name}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{upt}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{tpv}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{sales_total_units}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{return_total_units}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{purchase_total_units}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(sales_receipt)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(sales_nf_receipt)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(sales_invoice)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(sales_total)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(return_total)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(purchase_total)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(balance)}</Typography></TableCell>
                                        <TableCell className={classes.cell} ><Typography variant='body2'>{onGetCurrencyValue(total_cost)}</Typography></TableCell>
                                    </TableRow>
                                )
                            })}
                            
                        </TableBody>
                    </Table>
                </div>           
            ): null}     
        </div>
    )
}

export default TotalTable

const users= {
    value:null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config: {
        id: 'name',
        type: 'text',
        fullWidth: true,
    },
    rules: {type: 'distance', min:1, max:255}
}