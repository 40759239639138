import { connect } from 'react-redux';
import Page from '../../components/Structure/Layouts/PageAdmin'
import system_translations from '../../texts/system_translations'
import SimpleTable from './components/SimpleTable'
import AddProviderModal from './modals/AddProviderModal/AddProviderModal';
import EditClientModal from './modals/EditClientModal/EditClientModal';
import useMissingView from './useMissingView';



const MissingView = ({language, history}) => {

    const content = system_translations[language].views.missing
    const {actions, modals, view_data, system} = useMissingView({language})

    return(
        <Page title={content.title} language={language}>
            <EditClientModal language={language} open={modals.edit_client} onClose={()=>actions.onChangeModalStatus('edit_client', false)} />
            <SimpleTable 
                content={content.table} 
                data={view_data.missing}
                tableFilter={view_data.tableFilter}
                total={view_data.total}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onRefresh={actions.onInitModule}
                loading={system.loading}
                onDownloadExcel={actions.onDownloadExcel}
                onUpdateSearch={actions.onResetTableFiler}
            //onSelectedItem={() =>actions.onChangeModalStatus('edit_client', true)}
            //onAddRegister={()=>actions.onChangeModalStatus('edit_client', true)}
            />
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        language:state.language, 
    }
}

export default connect(mapStateToProps)(MissingView) 