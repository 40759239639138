import { Grid } from "@material-ui/core"
import AddEquivalenceModal from "./modals/AddEquivalenceModal/AddEquivalenceModal"
import DeleteProductModal from "./modals/DeleteProductModal"
import EditProductModal from "./modals/EditProductModal/EditProductModal"
import InfoCard from "./components/InfoCard"
import ShoppingGraph from './components/ShoppingGraph'
import ComplementTable from "./components/ComplemetTable"
import EquivalenceTable from "./components/EquivalenceTable"
import DeleteEquivalenceModal from "./modals/DeleteEquivalenceModal"
import AddComplementModal from "./modals/AddComplementModal/AddComplementModal"
import DeleteComplementModal from "./modals/DeleteComplementModal"


const ProductInfo = ({content, modals, onChangeModalStatus, language, product, complements, equivalences, actions, 
        history, nopicture, can_edit, equ_id, comp_id}) => {


    const shopping=[
        {month:'Septiembre', amount:18000},
        {month:'Octubre', amount:10000},
        {month:'Noviembre', amount:5000},
        {month:'Diciembre', amount:15000},
    ]
    
    const x_scale = ['0', '5k', '10k', '15k', '20k']

    return(
        <div>
            <EditProductModal content={content.general_info} open={modals.edit_product} onClose={()=>onChangeModalStatus('edit_product', false)}
                language={language} origin={product} onCompleted={actions.onProductCompleted} nopicture={nopicture}/>
            <DeleteProductModal history={history} language={language} open={modals.delete_product} onClose={()=>onChangeModalStatus('delete_product', false)} 
                id={product ? product.id_product : null}/>
            <AddEquivalenceModal open={modals.add_equivalence} onClose={()=>onChangeModalStatus('add_equivalence', false)} 
                language={language} id={product ? product.id_product : null} onCompleted={actions.onEqCompleted}/>
            <DeleteEquivalenceModal history={history} language={language} open={modals.delete_equivalence} onClose={()=>onChangeModalStatus('delete_equivalence', false)} 
                id={equ_id} onCompleted={actions.onEqCompleted}/>
            <AddComplementModal open={modals.add_complement} onClose={()=>onChangeModalStatus('add_complement', false)} 
                language={language} id={product ? product.id_product : null} onCompleted={actions.onCompCompleted}/>
            <DeleteComplementModal history={history} language={language} open={modals.delete_complement} onClose={()=>onChangeModalStatus('delete_complement', false)} 
                id={comp_id} onCompleted={actions.onCompCompleted}/>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <InfoCard content={content.general_info} onChangeModalStatus={onChangeModalStatus} {...product} 
                    nopicture={nopicture} can_edit={can_edit}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <EquivalenceTable onDelete={actions.onDeleteEquivalence} content={content.equivalences} rows={equivalences} 
                    onAdd={()=>onChangeModalStatus('add_equivalence', true)} can_edit={can_edit} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <ComplementTable content={content.complementary} rows={complements} onDelete={actions.onDeleteComplement}
                    onAdd={()=>onChangeModalStatus('add_complement', true)} can_edit={can_edit} />
                </Grid>
                {/* <Grid item xs={12}>
                    <ShoppingGraph data={shopping} x_scale={x_scale} limit={20000}/>
                </Grid> */}
            </Grid> 
        </div>
    )
}

export default ProductInfo