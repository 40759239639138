import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import WebsiteRouter from '../../routes/website-router';
import { actionTypes } from '../../store/actions';
import Topbar from './components/Topbar';
import cx from 'classnames'
import Sidebar from './components/Sidebar';
import useWebsiteLayout from './useWebsiteLayout';
import ErrorModal from '../../components/Modals/ErrorModal';
import { DASHBOARD_SETTINGS, TOKEN_STATUSES } from '../../config';
import { onGetTokenFromMemory, onValidateToken } from '../../shared/utility';
import system_translations from '../../texts/system_translations';
import { PrinterProvider } from '../../providers/PrinterProvider/PrinterContext';


const WebsiteLayout = ({blur, history, language, error, user, drawer_status, onUpdateUserData, onUpdateAuthStatus, onUpdateCart, onUpdateError}) => {

    const classes = useStyles()
    const theme = useTheme()
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'))
    const content = system_translations[language].general

    const { } = useWebsiteLayout({onUpdateUserData, onUpdateAuthStatus, onUpdateCart, history})
    
    const session_data = onGetTokenFromMemory()
    //console.log(session_data)
    const isAuth = onValidateToken(session_data)


    

    return ( 
        <PrinterProvider>
            <div className={cx({
                [classes.root]:true,
                [classes.root_blur]:blur
            })}>
                <ErrorModal open={Boolean(error)} message={error ? error.message : null}
                onClose={() => onUpdateError(null)} language={language}/>
                <main className={classes.main} >           
                    <Topbar ismobile={ismobile} content={content.topbar} language={language} history={history}/>
                    <Sidebar ismobile={ismobile} user={user} />
                    <div className={cx({
                        [classes.subroot]:true,
                        [classes.subroot_shift]: !drawer_status,
                    })}>
                        {isAuth === TOKEN_STATUSES.VALID || isAuth ===  TOKEN_STATUSES.REAUTH ?  <WebsiteRouter/> : <Redirect to='/signin'/>}
                    </div>
                </main>       
            </div>
        </PrinterProvider>
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'#FBFBFB',
        minHeight:'100vh',
        overflowY:'auto',
        filter:'none'

    },
    root_blur:{
        filter:'blur(3px)'
    },
    main:{
        //marginLeft:150,
        //padding:16,
        overflowY:'auto',
        //background:'red'
        
    },
    subroot:{
        marginTop:72,
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: DASHBOARD_SETTINGS.SIDEBAR.WIDTH,
        [theme.breakpoints.down('sm')]: {
            marginLeft:0
          },
    },
    subroot_shift:{
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        
    },
}))

const mapStateToProps = state => {
    return {
        is_auth: state.is_auth,
        ip_address:state.ip_address,
        blur: state.blur,
        user: state.user,
        error: state.error,
        drawer_status:state.drawer_status,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateUserData: (user) => dispatch({type:actionTypes.AUTH_UPDATE_USER, user}),
        onUpdateAuthStatus: (is_auth) => dispatch({type:actionTypes.AUTH_UPDATE_STATUS, is_auth}),
        onUpdateError: (error) => dispatch({type:actionTypes.SYS_UPDATE_ERROR, error})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteLayout);

